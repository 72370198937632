var init = function () {
  mobileNavIndicator = $('#mobileNavIndicator');
  mobileDrawer = $('#mobileDrawer');
  mobileDrawerOffset = $('#mobileDrawerOffset');
  mobileDrawerClose = $('#mobileDrawerClose');

  mobileNavIndicator.click(function () {
    var state = mobileDrawer.hasClass('is-open');
    if (!state) {
      mobileDrawer.addClass('is-open');
      mobileDrawerOffset.addClass('is-visible');
    }
  });

  mobileDrawerOffset.click(function () {
    mobileDrawer.removeClass('is-open');
    mobileDrawerOffset.removeClass('is-visible');
    closeAllMobileExpands();
  });

  mobileDrawerClose.click(function () {
    mobileDrawer.removeClass('is-open');
    mobileDrawerOffset.removeClass('is-visible');
    closeAllMobileExpands();
  });

  // Mobile Expanders
  var mobileDrawerExpandRoot = mobileDrawer.find('.main-nav__tabs-wrapper');

  if (mobileDrawerExpandRoot.length > 0) {
    $(mobileDrawerExpandRoot).each(function (index) {
      var element = mobileDrawerExpandRoot[index];

      var contentHeight = $(element).find('ul.main-nav__tabs')[0];
      $(element).attr('data-height', contentHeight.clientHeight).attr('data-id', index).css('height', '0px');
      $(element).prev('.mobile-menu__label').attr('data-id', index);
    });
  }

  // Mobile Expander Actions
  var mobileActivators = mobileDrawer.find('.has-children');
  $(mobileActivators).each(function (index) {
    var element = mobileActivators[index];

    $(element).click(function () {
      var state = $(this).hasClass('is-open');
      var id = $(this).data('id');
      var wrapper = $('.main-nav__tabs-wrapper[data-id="' + id + '"]');
      var height = $(wrapper).data('height') + 'px';
      var contentHeight = $(wrapper);

      if (state) {
        // Close
        $(this).removeClass('is-open');
        $(contentHeight).css('height', '0px');
      } else {
        // Open
        closeAllMobileExpands();
        $(this).addClass('is-open');
        $(contentHeight).css('height', height);
      }
    });
  });

  var closeAllMobileExpands = function () {
    var opens = mobileDrawer.find('.is-open');

    if (opens.length > 0) {
      $(opens).each(function (index) {
        var element = opens[index];
        var id = $(element).data('id');
        var wrapper = $('.main-nav__tabs-wrapper[data-id="' + id + '"]');

        $(element).removeClass('is-open');
        $(wrapper).css('height', '0px');
      });
    }
  };

  // Slides
  $('#slides').slidesjs({
    width: 940,
    height: 528,
    play: {
      active: true,
      auto: true,
      interval: 4000,
      swap: true
    }
  });

  // Cookie
  var cc = initCookieConsent();

  // example logo
  var logo = '<div class="cookie-logo-header"><img src="/images/hdr_logo_100.png" alt="Logo" loading="lazy" style="height: 35px"><div class="cookie-logo-header__text pl-3">CHRISTMAS STOCKHOLDERS LTD</div></div>';

  // run plugin with config object
  cc.run({
    current_lang: 'en',
    autoclear_cookies: true,                   // default: false
    cookie_name: 'christmas_ga_cookie',             // default: 'cc_cookie'
    cookie_expiration: 365,                    // default: 182
    page_scripts: true,                         // default: false

    // auto_language: null,                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                           // default: true
    // delay: 0,                                // default: 0
    // force_consent: false,
    // hide_from_bots: false,                   // default: false
    // remove_cookie_tables: false              // default: false
    // cookie_domain: location.hostname,        // default: current domain
    // cookie_path: "/",                        // default: root
    // cookie_same_site: "Lax",
    // use_rfc_cookie: false,                   // default: false
    // revision: 0,                             // default: 0

    gui_options: {
      consent_modal: {
        layout: 'cloud',                      // box,cloud,bar
        position: 'bottom center',           // bottom,middle,top + left,right,center
        transition: 'slide'                 // zoom,slide
      },
      settings_modal: {
        layout: 'box',                      // box,bar
        // position: 'left',                // right,left (available only if bar layout selected)
        transition: 'zoom'                 // zoom,slide
      }
    },

    languages: {
      'en': {
        consent_modal: {
          title: 'We use cookies! ',
          description: 'We uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          primary_btn: {
            text: 'Accept all',
            role: 'accept_all'              // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Reject all',
            role: 'accept_necessary'        // 'settings' or 'accept_necessary'
          }
        },
        settings_modal: {
          title: logo,
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          cookie_table_headers: [
            { col1: 'Name' },
            { col2: 'Domain' },
            { col3: 'Expiration' },
            // { col4: 'Description' }
          ],
          blocks: [
            {
              title: 'Cookie usage',
              description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want..'
            }, {
              title: 'Strictly necessary cookies',
              description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
              }
            }, {
              title: 'Performance and Analytics cookies',
              description: 'These cookies allow the website to remember the choices you have made in the past',
              toggle: {
                value: 'analytics',     // there are no default categories => you specify them
                enabled: false,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '^_ga',
                  col2: 'google.com',
                  col3: '2 years',
                  // col4: 'description ...',
                  is_regex: true
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: '1 day',
                  // col4: 'description ...',
                }
              ]
            }
          ]
        }
      }
    }

  });
};

$(document).ready(function () {
  init();
});
